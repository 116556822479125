
 
export const triggerTiktokEvent = async (data) => {
    try {
        const response = await fetch(`${window.location.origin}/trigger_tiktok_event`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'cf2b7c9c5f743fb64a83bb278a8918c4', 
                'X-Api-Secret': '72e638acaed194ba4c37cec362ab98964279a5ad1ea9807d43de59c485c6194e'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const responseData = await response.json(); 
            console.log(responseData);
            return responseData; 
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        return 'not_sent';
    }
}



export const triggerFacebookEvent = async (data) => {
    try {
        const response = await fetch(`${window.location.origin}/trigger_facebook_event`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'cf2b7c9c5f743fb64a83bb278a8918c4', 
                'X-Api-Secret': '72e638acaed194ba4c37cec362ab98964279a5ad1ea9807d43de59c485c6194e'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const responseData = await response.json(); 
            console.log(responseData);
            return responseData; 
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        return 'not_sent';
    }
};


export const triggerBranchEvent = async (data) => {
    try {
        const response = await fetch(`${window.location.origin}/trigger_branch_event`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': 'cf2b7c9c5f743fb64a83bb278a8918c4', 
                'X-Api-Secret': '72e638acaed194ba4c37cec362ab98964279a5ad1ea9807d43de59c485c6194e'
            },
            body: JSON.stringify(data)
        });

        if (response.ok) {
            const responseData = await response.json(); 
            console.log(responseData);
            return responseData; 
        } else {
            throw new Error('Network response was not ok.');
        }
    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        return 'not_sent';
    }
};
