import React, { Suspense, useRef, useState, useEffect } from "react";
import { StartSpinner, StopSpinner } from "../common/Spinner/spinner";
import showToast from "../common/toastify";
import { AppService } from "../services/AppService";
import { FirebaseService } from "../services/FirebaseService";
import EmailPasswordLoginComponent from "./components/EmailPasswordLoginComponent";
import PhoneLoginComponent from "./components/PhoneLoginComponent";
import { triggerFacebookEvent,triggerBranchEvent,triggerTiktokEvent } from '../facebook_conversion';


const SocialLoginComponent = React.lazy(() =>
  import("./components/SocialLoginComponent")
);
const currentDate = new Date()
currentDate.setFullYear(currentDate.getFullYear() - 18);
export const LoginComponent = ({
  mode,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loginType, setLoginType] = useState("");
  const [messagingToken, setMessagingToken] = useState('');
  const [initial, setInitial] = useState(false);
  const inputRef = useRef(null);
  const [formFields, setFormFields] = useState({
    phone: "",
    first_name: "",
    last_name: "",
    dob: moment(currentDate),
    countryCode: "",
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
    email: "",
    password: "",
    re_enter_password: "",
    referral:"",
    marketing_email_subscription:false,
    address:"",
    city: "",
    state: "",
    country: "",
    zip: "",
    lat: "",
    long: "",
  });

  const [oldAddressValue, setOldAddressValue] = useState("")
  const [bookingFlowData, setBookingFlowData] = useState(sessionStorage.getItem("bookingFlowData") ? JSON.parse(sessionStorage.getItem("bookingFlowData") || {}) : null)


  const getToken = async () => {
    try {
      const token = await FirebaseService.getMessagingToken();
      if (token) {
        setMessagingToken(token);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const saveUser = async (
    uid,
    first_name,
    last_name,
    displayName,
    email,
    phone,
    dob,
    referral,
    marketing_email_subscription,
    u_address,
    u_city,
    u_state,
    u_country,
    u_zip,
    u_lat,
    u_long,    
    source,
    verified_email,
    verified_phone
  ) => {
    try {
      if (!first_name && !last_name && displayName) {
        const splittedName = displayName.split(" ");
        first_name = splittedName[0] || '';
        if(splittedName?.length > 1){
          last_name = splittedName?.slice(1)?.join(' ') || ''
        }
        // last_name = splittedName.pop();
        // first_name = splittedName.join(" ");
      }

      const url = window.location.href;

      var role = '';
      if (url.includes('photographer')) {
        role = 'photographer';
      } else {
        role = 'client';
      }

      const user = {
        uid,
        first_name,
        last_name,
        email,
        phone,
        dob,
        source,
        verified_email,
        verified_phone,
        referral,
        marketing_email_subscription,
        u_address,
        u_city,
        u_state,
        u_country,
        u_zip,
        u_lat,
        u_long,
        role,
        operating_system: "Web"
      };

      const response = await fetch("/save-firebase-user.json", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...user,
          registration_token: messagingToken
        }),
      });
      const data = await response.json();
      await triggerFacebookEvent({
        "fb_event_name": "AccountCreated",
        "user_id": data?.user?.id,
        "action_source": "website",
      })
      await triggerBranchEvent({
        "branch_event_name": "AccountCreated",
        "user_id": data?.user?.id,
      });
      await triggerTiktokEvent({
        source:"web",
        user_id:data?.user?.id,
        tiktok_event_name: "CompleteRegistration",
      })
      await triggerTiktokEvent({
        source:"web",
        user_id:data?.user?.id,
        tiktok_event_name: "AccountCreated",
      })
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'account_created_web',
        userUid: uid,
        userSource: source
      });
      return data;
    } catch (e) {
      console.log(e);
      showToast('Unable to create user.', 'error');
    }
  };

  const handleUserSignIn = async (firebaseUser, credential, loginType, otherFields = null) => {
    StartSpinner();
    try {
      console.log(firebaseUser, credential, loginType);
      if (mode == 'register') {
        let existsFor = loginType == "phone" ? "phone" : "email"
        const resp = await fetch(
          "/user-exists?"+existsFor+"=" + encodeURIComponent(loginType == "phone" ? firebaseUser?.phoneNumber : firebaseUser?.email)
        );
        const userData = await resp.json();
        if (userData.exists) {
          showToast(`The account already exists for that ${existsFor}. Please login`, "error", false);
          StopSpinner();
          return true ;
        }
        const data = await saveUser(
          firebaseUser.uid,
          otherFields?.first_name || "",
          otherFields?.last_name || "",
          firebaseUser.displayName,
          firebaseUser.email,
          firebaseUser.phoneNumber,
          otherFields?.dob || '',
          otherFields?.referral || '',
          otherFields?.marketing_email_subscription ?? false,
          otherFields?.address,
          otherFields?.city,
          otherFields?.state,
          otherFields?.country,
          otherFields?.zip,
          otherFields?.lat,
          otherFields?.long,
          loginType,
          firebaseUser.emailVerified,
          loginType == 'phone'
        );
      }

      await AppService.doServerLogin(firebaseUser.accessToken, '',null,loginType,firebaseUser);

    } catch (e) {
      console.log(e);
      showToast('Unable to login.', 'error');
    }
    StopSpinner();
  }

  useEffect(() => {
    if (inputValue) {
      var phoneRegex =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
      var emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

      if (phoneRegex.test(inputValue) && emailRegex.test(inputValue)) {
        setLoginType("");
      } else if (emailRegex.test(inputValue)) {
        setLoginType("email");
      } else if (phoneRegex.test(inputValue)) {
        setLoginType("phone");
      } else {
        if (location.href.includes('/register')) {
          setLoginType("email");
        }
        else setLoginType("");
      }
    } else {
      if (location.href.includes('/register')) {
        setLoginType("email");
      }
      else setLoginType("");
    }
  }, [inputValue]);

  useEffect(() => {
    if (
      loginType == "" &&
      inputRef.current &&
      document.activeElement !== inputRef.current
      && mode == 'login'
    ) {
      inputRef.current.focus();
    }
  }, [loginType]);


  useEffect(() => {
    const loginWrapper = document.querySelector('.login_wrapper');
    if (loginWrapper) {
      loginWrapper.classList.remove('d-none');
    }
  }, []);

  useEffect(() => {
    getToken();
  }, [])




  // useEffect(() => {
  //   if (location.href.includes('/register')) {
  //     setInitial(true);
  //     setLoginType("email");
  //   } else {
  //     setInitial(true);
  //     setLoginType("");
  //   }
  // }, [])

  return (
    <>
      {true ?
        <>
          {mode == 'login' ? <>
            {/* <img src={ASSETS.cheezoo_logo_big} onClick={e => {location.href=window.location.origin}} class="img-fluid mb-4 footer-hide-mobile" style={{ width: "300px",cursor:'pointer' }} /> */}

            {/* <h2 class="heading2">Get Started</h2>
            <p></p> */}
          </> : ""}
          <div className="login-form">
            {loginType === "" && (
              <form autoComplete="off">
                <div className="form-floating mb-4 me-md-2">
                  <input type="text" className="form-control" id="Phone" placeholder="Phone or Email"
                    autoComplete="off"
                    required
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <label htmlFor="Phone">Phone or Email</label>
                </div>
                {mode == "login" ?
                  <button type="button" className="btn-w-full btn-gray mb-4"
                    onClick={(e) => {
                      e.preventDefault();
                      if (inputValue.includes('@'))
                        showToast("Please enter your registered email")
                      else if (/^\d+$/.test(inputValue))
                        showToast("Please enter your registered phone number")
                      else
                        showToast("Please enter your registered email id / phone number")
                    }}
                  >
                    Sign In{" "}
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.66039 15.1848C5.26987 14.7943 4.6367 14.7943 4.24618 15.1848C3.85566 15.5753 3.85566 16.2085 4.24618 16.599L5.66039 15.1848ZM4.24618 3.39969C3.85566 3.79021 3.85566 4.42338 4.24618 4.8139C4.6367 5.20442 5.26987 5.20442 5.66039 4.8139L4.24618 3.39969ZM10.4147 6.83519C10.0478 6.42241 9.41574 6.38522 9.00295 6.75214C8.59017 7.11906 8.55299 7.75113 8.91991 8.16392L10.4147 6.83519ZM11.8895 9.99955L12.6369 10.6639C12.9737 10.285 12.9737 9.71407 12.6369 9.33519L11.8895 9.99955ZM8.91991 11.8352C8.55299 12.248 8.59017 12.88 9.00295 13.247C9.41574 13.6139 10.0478 13.5767 10.4147 13.1639L8.91991 11.8352ZM11.7507 10.9996C12.3029 10.9996 12.7507 10.5518 12.7507 9.99955C12.7507 9.44727 12.3029 8.99955 11.7507 8.99955V10.9996ZM1.33398 8.99955C0.7817 8.99955 0.333984 9.44727 0.333984 9.99955C0.333984 10.5518 0.7817 10.9996 1.33398 10.9996V8.99955ZM16.0313 4.8139C18.8951 7.67774 18.8951 12.321 16.0313 15.1848L17.4455 16.599C21.0904 12.9541 21.0904 7.04458 17.4455 3.39969L16.0313 4.8139ZM16.0313 15.1848C13.1674 18.0486 8.52424 18.0486 5.66039 15.1848L4.24618 16.599C7.89107 20.2439 13.8006 20.2439 17.4455 16.599L16.0313 15.1848ZM5.66039 4.8139C8.52424 1.95005 13.1674 1.95005 16.0313 4.8139L17.4455 3.39969C13.8006 -0.245207 7.89107 -0.245208 4.24618 3.39969L5.66039 4.8139ZM8.91991 8.16392L11.1421 10.6639L12.6369 9.33519L10.4147 6.83519L8.91991 8.16392ZM11.1421 9.33519L8.91991 11.8352L10.4147 13.1639L12.6369 10.6639L11.1421 9.33519ZM11.7507 8.99955H1.33398V10.9996H11.7507V8.99955Z"
                        fill="white"
                      />
                    </svg>
                  </button> : ""}
              </form>
            )}

            {loginType === "email" && (
              <>
              {/* {window.location.href.includes('register-client') && rData?.bookingFlow && bookingFlowData?.flow === 'booknow' ? 
              <small>To continue with booking this photographer, signup for an account below</small> : ""} */}
              <EmailPasswordLoginComponent
                email={inputValue}
                formFields={formFields}
                setFormFields={setFormFields}
                oldAddressValue={oldAddressValue}
                setOldAddressValue={setOldAddressValue}
                setInputValue={setInputValue}
                mode={mode}
                onInputChange={(e) => setInputValue(e)}
                onUserSignIn={handleUserSignIn}
              />
              </>
            )}
            {loginType === "phone" && (
              <PhoneLoginComponent
                phone={inputValue}
                formFields={formFields}
                setFormFields={setFormFields}
                oldAddressValue={oldAddressValue}
                setOldAddressValue={setOldAddressValue}
                setInputValue={setInputValue}
                mode={mode}
                onInputChange={(e) => setInputValue(e)}
                onUserSignIn={handleUserSignIn}
              />
            )}
            {/* {
              mode == 'register' ? (
                <p className="member-yet">Already a member? <a href="/login" className="text-decoration-underline underline-grey"><b>Sign In</b></a></p>
              ) : (
                <p className="member-yet">
                  Not a member yet? <b><u className="underline-grey"><a href="/choose-role">Sign Up</a></u></b> <br />
                </p>
              )
            } */}

            <div className="login_social">
              {/* <p className="text-grey">Or</p> */}
              <Suspense fallback={<div>Please Wait...</div>}>
                <SocialLoginComponent
                  mode={mode}
                  onUserSignIn={handleUserSignIn}
                />
              </Suspense>

              {/* {mode != 'register' && (
                <a href="/"><p>Start as a Guest</p></a>
              )} */}
            </div>
            {/* <small>By signing {mode == 'login' ? "in" : "up"}, you agree to our <a href="https://www.cheezoo.com/privacy-policy/" alt="privacy policy">Privacy Policy </a> and <a href="https://www.cheezoo.com/terms-of-use" alt="terms" style={{fontWeight:"bold",textDecoration:"underline"}}>Terms of Use</a></small> */}

            <small style={{display: "flex",flexWrap: "wrap",justifyContent: "center"}}>
              By signing {mode == 'login' ? "in" : "up"}, you agree to our
              <div className="link-container" style={{whiteSpace: "nowrap"}}>
                <a className="privacy-link" href="https://www.cheezoo.com/privacy-policy/" alt="privacy policy" style={{fontWeight:"bold",textDecoration:"underline",marginLeft:"5px"}} >Privacy Policy</a>
                <span style={{margin: "0 5px"}}>and</span>
                <a href="https://www.cheezoo.com/terms-of-use" alt="terms" style={{fontWeight:"bold",textDecoration:"underline"}} >Terms of Use</a>
              </div>
            </small>
          </div >
        </>
        : ""}
    {window.location.href.includes('register-client') && rData?.bookingFlow && bookingFlowData?.flow === 'booknow' ? 
      
      <div className="find-or-be-button">
        <span className="my-3 d-block">Or</span>
        <p className=""><u style={{cursor:"pointer"}} onClick={(e) => location.href = "/login"}>Sign In</u></p>
      </div>
    : ""}
    </>
  );
};

export default LoginComponent;
